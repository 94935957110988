import React from "react"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import Paragraph from "../../../../components/paragraph"
import { getCourseNavigation } from "../../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zum nächsten Kapitel"
          to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte"
        />
      }
      navigation={navigation}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h2" level={2}>
              Zusammenfassung
            </Heading>
          </Stack>
          <Paragraph>
            Mit seinen 2 Strophen und 14 Versen zählt ›Hälfte des Lebens‹ zwar
            zu Hölderlins kürzesten Gedichten, dennoch hat er darin eine Menge
            raffinierter Details verbaut:
          </Paragraph>
          <Paragraph>
            Er hat die Versgrenzen, Enjambements und Satzkonstruktionen
            geschickt auf die Bilder und Themen des Gedichts abgestimmt. Durch
            die Wahl der Wörter und Anordnung der Verse werden die beiden
            Strophen als Gegensatz-Paar gestaltet:
          </Paragraph>
          <Paragraph>
            Die 1. Strophe beschreibt eine üppige Sommerlandschaft, in der alles
            miteinander im Einklang ist. In der 2. Strophe herrscht dagegen ein
            karger, trostloser Winter, der wenig Hoffnung verspricht. Zwei
            Strophen – zwei Lebenshälften, die einander gegenüberstehen.
          </Paragraph>
          <Paragraph>
            Ein Gedicht über eine Midlife-Crisis? Nicht nur! In den beiden
            Strophen hat Hölderlin eine Reihe von Symmetrien versteckt. Die
            dazwischenstehende Leerzeile wird hierbei zu einer Art Spiegel.
            Durch Alliterationen und Assonanzen hat Hölderlin musikalische
            Motive in das Gedicht eingearbeitet. Außerdem hat er beim Bau des
            Gedichts auf antike Versformen wie den Adonius zurückgegriffen. Die
            griechische Antike – das war in seinen Augen eine Epoche des
            kulturellen Reichtums, in der Kunst und Leben noch untrennbar
            miteinander verbunden und Gott, Mensch und Natur im Einklang
            miteinander standen. So könnte es in dem Gedicht auch um die
            Verhandlung eines dichterischen Selbstverständnisses gehen: Ein
            Dichter blickt zurück auf den Reichtum der griechischen Kultur und
            sieht seiner eigenen Zukunft, in der sich Kunst und Leben immer
            weiter voneinander entfernen, kritisch und ängstlich entgegen.
          </Paragraph>
          <Paragraph>
            Zuletzt hast du dir verschiedene Vortragsweisen des Gedichts
            angehört und dich an eine eigene sprecherische Interpretation des
            Gedichts gewagt.
          </Paragraph>
          <Paragraph>
            Willst du mehr über die Entstehung des Gedichts wissen?
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
